import React from 'react';

function NavBar() {
  return (
    <nav className="w-full bg-white shadow-md p-4">
      <div className="container mx-auto flex justify-between items-center">
        <ul className="flex space-x-8">
          <li>
            <a
              href="#home"
              className="text-gray-700 hover:text-blue-600 transition-colors duration-200"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#about"
              className="text-gray-700 hover:text-blue-600 transition-colors duration-200"
            >
              About
            </a>
          </li>
          <li>
            <a
              href="#docs"
              className="text-gray-700 hover:text-blue-600 transition-colors duration-200"
            >
              Docs
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
