import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
  	<span>Copyright Galwomin {currentYear}</span>
  );
}

export default Footer;
