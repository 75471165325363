import React from 'react';

function MainContent() {
  return (
    <main className="flex-1 p-6">
      <h2 className="text-2xl font-bold mb-4">Welcome to Galwomin!</h2>
      <p></p>
      {/* Add more sections here */}
    </main>
  );
}

export default MainContent;
